<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.return_security_money') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Allocation Type" vid="allocation_type" rules="required" v-slot="{ errors }">
                    <b-form-group
                      label-for="allocation_type"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.allocation_type') }}
                      </template>
                      <b-form-select
                        plain
                        id="allocation_type"
                        :options="allocationType"
                        v-model="search.allocation_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.return_security_money') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" v-if="finalList.length > 0" size="sm" @click="pdfExport" class="btn-add">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
        <export-excel
          v-if="finalList.length > 0"
          class="btn-add ml-2"
          :data="excelData"
          :title="headerValue"
          :fields=excelFields
          worksheet="Report Sheet"
          :default-value="headerExcelDefault"
          name="security_deposite_return_report.xls">
          {{ $t('globalTrans.export_excel') }}
        </export-excel>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.return_security_money') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="12" class="text-left">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ parseInt(search.stall_category) ? getStallCategoryName(search.stall_category) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <template>
                    <table style="width:100%;" class="mb-4 table-responsive">
                      <thead>
                        <tr>
                          <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                          <th class="text-center" style="width: 10%;">{{ $t('exportCipReport.org_name_address') }}</th>
                          <th class="text-center" style="width: 10%;">{{ $t('fair_report.applicant_name_phone_number') }}</th>
                          <th class="text-center" style="width: 5%;">{{ $t('fair_report.allocated_stall') }}</th>
                          <!-- <th class="text-center" style="width: 5%;">{{ $t('fair_report.stall_type') }}</th> -->
                          <!-- <th class="text-center">{{ $t('fair_report.processing_fee_deposit') }}</th> -->
                          <th class="text-center" style="width: 10%;">{{ $t('fair_report.security_fee_deposit') }}</th>
                          <th class="text-center" style="width: 10%;">{{ $t('fair_report.allotment_fee') }}</th>
                          <th class="text-center" style="width: 10%;">{{ $t('globalTrans.vat') }} {{ '('+$n(15) + '%'+')' }} {{ currentLocale == 'bn' ? 'ও' : '&'}}  {{ $t('globalTrans.tax') }} {{ '('+$n(10) + '%'+')' }}</th>
                          <th class="text-center" style="width: 10%;">{{ $t('fair_report.refundable_security_money') }}</th>
                          <th class="text-center" style="width: 15%;">{{ $t('externalTradeFair.refund_bank_info')}}</th>
                          <th class="text-center" style="width: 5%;">{{ $t('globalTrans.comments') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalList.length > 0">
                        <template v-for="(app, index) in finalList">
                          <tr v-for="(pavilion, index1) in app.stall_categories" :key="index1">
                            <slot v-if="index1 === 0">
                              <td :rowspan="app.stall_categories.length">{{ $n(index + 1) }}</td>
                              <td :rowspan="app.stall_categories.length">
                                <strong>{{ currentLocale == 'bn' ?  app.comp_name_bn : app.comp_name_en }}</strong>, <br>
                                {{ AddressHelper.getShortAddress(app.address) }}
                              </td>
                              <td :rowspan="app.stall_categories.length">
                                <strong>{{ currentLocale == 'bn' ?  app.name_bn : app.name_en }}</strong>, <br>
                                <strong>{{ app.mobile_no | mobileNumber }}</strong>
                              </td>
                            </slot>
                            <td>
                              <span v-if="app?.booked_pavilion.length > 0">
                                {{ getStallName(pavilion.stall_cat_id, app?.booked_pavilion) }}
                              </span>
                            </td>
                            <!-- <td>{{ getStallCategoryName(pavilion.stall_cat_id) }}</td> -->
                            <!-- <td class="text-right">
                              {{$t('fair_report.taka')}} {{ $n(pavilion.processing_fee, { minimumFractionDigits: 2 }) }} <br>
                              {{$t('globalTrans.transaction_no')}} : {{ app.app_payment?.transaction_no }} <br>
                              {{$t('globalTrans.date')}} : {{ app.app_payment?.pay_date | dateFormat }}
                            </td> -->
                            <td class="text-right">
                              {{$t('fair_report.taka')}} {{ $n(pavilion.quoted_price * 0.25, { minimumFractionDigits: 2 }) }} <br>
                              {{$t('globalTrans.transaction_no')}} : {{ app.app_payment?.transaction_no }} <br>
                              {{$t('globalTrans.date')}} : {{ app.app_payment?.pay_date | dateFormat }}
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                <span v-if="getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0">
                                   {{$n(getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} )}}
                                  <!-- <span v-for="(stallPay, ip) in app?.app_stall_payment_pay" :key="ip">
                                      <span :key="ip" v-if="stallPay.stall_cat_id == pavilion.stall_cat_id">
                                        {{$t('globalTrans.transaction_no')}} : {{ stallPay?.transaction_no }} <br>
                                        {{$t('globalTrans.date')}} : {{ stallPay?.pay_date | dateFormat }}
                                      </span>
                                  </span> -->
                                </span>
                              </span>
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                {{ getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? $t('globalTrans.vat') +' '+$t('fair_report.taka') +' '+ $n(getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} ) : '' }}
                              </span>
                              <br>
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                {{ getVat(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? $t('globalTrans.tax') +' '+$t('fair_report.taka') +' '+ $n(getVat(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} ) : '' }}
                                <span v-for="(stallPay, ip) in app?.app_stall_payment_pay" :key="ip">
                                    <span :key="ip" v-if="stallPay.stall_cat_id == pavilion.stall_cat_id">
                                      {{$t('globalTrans.transaction_no')}} : {{ stallPay?.transaction_no }} <br>
                                      {{$t('globalTrans.date')}} : {{ stallPay?.pay_date | dateFormat }}
                                    </span>
                                </span>
                              </span>
                            </td>
                            <td class="text-right">
                              <!-- <span v-if="app?.app_refund.length > 0">
                                {{ getRefundAmount(pavilion.stall_cat_id, app?.app_refund) > 0 ? $n(getRefundAmount(pavilion.stall_cat_id, app?.app_refund), { minimumFractionDigits: 2} ) : '' }}
                              </span> -->
                               {{ $n(pavilion.quoted_price * 0.25, { minimumFractionDigits: 2 }) }}
                            </td>
                            <td>
                              <p class="text-left" v-if="app?.refund_bank_info">
                                {{ $t('eBizProgram.bank_name')}} : {{ app?.refund_bank_info?.bank_name }} <br>
                                {{ $t('eBizProgram.branch_name')}} : {{ app?.refund_bank_info?.branch_name }} <br>
                                {{ $t('externalTradeFair.account_name')}} : {{ app?.refund_bank_info?.account_name }} <br>
                                {{ $t('externalTradeFair.bank_account_no')}} : {{ app?.refund_bank_info?.account_no }} <br>
                                {{ $t('externalTradeFair.routing_no')}} : {{ app?.refund_bank_info?.routing_no }}
                              </p>
                            </td>
                            <td>
                              <span v-if="app?.booked_pavilion.length > 0 && app?.booked_pavilion !==  undefined">
                                <span class="text-danger" v-if="app?.booked_pavilion[0].status == 2 && app?.booked_pavilion[0].is_paid != 2 && new Date(app?.circular.stallment_payment_last_date) < new Date()">{{ $i18n.locale == 'bn' ? 'জামানত বাজেয়াপ্ত' : 'Security Money Forfeited' }}</span>
                              </span>
                            </td>
                          </tr>
                        </template>
                        <tr>
                            <td colspan="4" class="text-right">{{ $t('globalTrans.total') }}</td>
                            <!-- <td class="text-right">{{ $n(getTotalProcessingFee(), { minimumFractionDigits: 2}) }}</td> -->
                            <td class="text-right">{{ $n(getTotalSecurityMoney(), { minimumFractionDigits: 2}) }}</td>
                            <!-- <td></td> -->
                            <td class="text-right">{{ $n(getTotalStallFee(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalTax() + getTotalVat(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalSecurityMoney(), { minimumFractionDigits: 2}) }}</td>
                            <td></td>
                            <td></td>
                          </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="11" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { retrunSecurityMoneyReport, getMasterStalls } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import excel from 'vue-excel-export'

import ModalBaseMasterList from '@/mixins/list'
import Vue from 'vue'
Vue.use(excel)
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
    this.getStall()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      priceType: 0,
      processingFee: 0,
      stallSize: '',
      circular: '',
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        allocation_type: 0,
        stall_id: 0
      },
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_id: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        // this.stallList = []
        this.search.stall_id = 0
        // this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    allocationType () {
      return [
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'বরাদ্দ হয়েছে' : 'Allocated'
        },
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'বরাদ্দ পায়নি' : 'Not allocated'
        }
      ]
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    headerValue: function () {
      const headerVal = []
      // const local = this.$i18n.locale
      if (this.$i18n.locale === 'en') {
        headerVal[0] = ''
        headerVal[1] = ''
        headerVal[2] = ''
        headerVal[3] = ''
      } else {
        headerVal[0] = ''
        headerVal[1] = ''
        headerVal[2] = ''
        headerVal[3] = ''
      }
      const fair = (this.search.fair_id ? this.getSearchFairName(this.search.fair_id) : this.$t('globalTrans.all'))
      let hedearVal = ''
      hedearVal = fair
      headerVal[0] = hedearVal
      headerVal[1] = this.$t('fair_report.return_security_money')
      const PriceCategoryName = this.$t('fair_report.space_category') + ' : ' + this.getCategoryName(this.search.stall_category)
      headerVal[2] = PriceCategoryName
      return headerVal
    },
    excelFields: function () {
      if (this.$i18n.locale === 'bn') {
        return {
          'ক্রমিক নং': 'serial',
          'প্রতিষ্ঠানের নাম ও ঠিকানা': 'org_name_add',
          'আবেদনকারীর নাম ও মোবাইল নম্বর': 'applicant_name_mobile',
          'বরাদ্দকৃত স্টল': 'booked_stall',
          'জামানত জমার বিবরণ': 'security_money_deposit_details',
          'বরাদ্দ ফি': 'allotment_fee',
          'ভ্যাট (১৫%) ও ট্যাক্স (১০%)': 'vat_and_tax',
          'ফেরতযোগ্য জামানতের পরিমাণ': 'refundable_security_money',
          'ব্যাংকের তথ্য বিস্তারিত': 'bank_details',
          'মন্তব্য ': 'comments'
        }
      } else {
        return {
          'SL No': 'serial',
          'Organization Name & Address': 'org_name_add',
          'Applicant Name & Mobile No': 'applicant_name_mobile',
          'Allocated Stall': 'booked_stall',
          'Security Money Deposit Details': 'security_money_deposit_details',
          'Allotment Fee': 'allotment_fee',
          'Vat (15%) & Tax (10%)': 'vat_and_tax',
          'Refundable Security Money': 'refundable_security_money',
          'Bank Iformation Details': 'bank_details',
          'Comments ': 'comments'
        }
      }
    },
    excelData: function () {
      const excelData = []
      this.finalList.forEach((app, index) => {
        app.stall_categories.forEach((pavilion, index1) => {
          // booked stall
          const bookedStall = app?.booked_pavilion.length > 0 ? this.getStallName(pavilion.stall_cat_id, app?.booked_pavilion) + ' ' : ' '
          // security money
          const securityMoney = this.$n(pavilion.quoted_price * 0.25, { minimumFractionDigits: 2 }) + '\n' + this.$t('globalTrans.transaction_no') + ' : ' + app.app_payment?.transaction_no + '\n' + this.$t('globalTrans.date') + ' : ' + app.app_payment?.pay_date
          // vat and tax
          const TransDate = app?.app_stall_payment_pay.map((stallPay, ip) => {
            if (stallPay.stall_cat_id === pavilion.stall_cat_id) {
              return this.$t('globalTrans.transaction_no') + ' : ' + stallPay?.transaction_no + '\n' + this.$t('globalTrans.date') + ' : ' + stallPay?.pay_date
            }
          })
          const vatTax = this.getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? this.$n(this.getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2 }) + '\n' + this.$n(this.getVat(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2 }) + '\n' + TransDate : ' '
          // bank details
          const bankDetails = app?.refund_bank_info ? this.$t('eBizProgram.bank_name') + ' : ' + app?.refund_bank_info?.bank_name + '\n' + this.$t('eBizProgram.branch_name') + ' : ' + app?.refund_bank_info?.branch_name + '\n' + this.$t('externalTradeFair.account_name') + ' : ' + app?.refund_bank_info?.account_name + '\n' + this.$t('externalTradeFair.bank_account_no') + ' : ' + app?.refund_bank_info?.account_no + '\n' + this.$t('externalTradeFair.routing_no') + ' : ' + app?.refund_bank_info?.routing_no + ' '
          : ' '
          // attoment fee
          const allotmentFee = this.getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? this.$n(this.getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2 }) + ' ' : ' '
          // comments
          // Default value
          let comment = ' '

          // Ensure `app` exists before accessing its properties
          if (app && Array.isArray(app.booked_pavilion) && app.booked_pavilion.length > 0) {
              const firstPavilion = app.booked_pavilion[0] // Get the first element safely
              if (
                  firstPavilion.status === 2 &&
                  firstPavilion.is_paid !== 2 &&
                  new Date(app.circular.stallment_payment_last_date) < new Date()
              ) {
                  comment = this.$i18n.locale === 'bn' ? 'জামানত বাজেয়াপ্ত' : 'Security Money Forfeited'
              }
          }

          const data = {
            serial: index + 1,
            org_name_add: this.$i18n.locale === 'bn' ? app.comp_name_bn + ', \n' + AddressHelper.getShortAddress(app.address) : app.comp_name_en + ', \n' + AddressHelper.getShortAddress(app.address),
            applicant_name_mobile: this.$i18n.locale === 'bn' ? app.name_bn + ',\n' + app.mobile_no : app.name_en + ', \n' + app.mobile_no,
            booked_stall: bookedStall,
            security_money_deposit_details: securityMoney,
            allotment_fee: allotmentFee,
            vat_and_tax: vatTax,
            refundable_security_money: this.$n(pavilion.quoted_price * 0.25, { minimumFractionDigits: 2 }),
            bank_details: bankDetails,
            comments: comment + ' '
          }
          excelData.push(data)
        })
      })
      return excelData
    }
  },
  methods: {
    headerDataExcel () {
      RestApi.getData(internationalTradeFairServiceBaseUrl, '/configuration/report-head/detail/' + this.orgId).then(response => {
      if (response.success) {
          const orgList = this.$store.state.CommonService.commonObj.componentOrgList.find(item => item.value === this.orgId)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
        }
      })
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    getStallCategoryName (id) {
        const dataStallCategory = this.stallCategoryList.find(item => item.value === parseInt(id))
        if (typeof dataStallCategory !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataStallCategory.text_en : dataStallCategory.text_bn
        } else {
          return ''
        }
    },
    getStallName (stallId, bookedPavilions) {
        const bookedStall = bookedPavilions.find(item => item.stall_cat_id === parseInt(stallId))

        if (bookedStall !== undefined) {
          const stallCategory = this.stallCategoryList.find(item => item.value === parseInt(bookedStall.stall_cat_id))

          if (parseInt(stallCategory.price_type) === 1) {
            return bookedStall.booked_stall_no
          } else {
            const stallData = this.stallList.find(item => item.value === parseInt(bookedStall.stall_id))
            return stallData !== undefined ? stallData.text : ''
          }
        } else {
          return ''
        }
    },
    getStallPayment (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)

      if (stallPayment) {
          const stallAmount = Number(stallPayment.payment_amount) - (Number(stallPayment.vat) + Number(stallPayment.tax))
          return stallAmount
      }
      return 0
    },
    getVat (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)
      if (stallPayment) {
          return stallPayment.vat
      }
      return 0
    },
    getTax (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)
      if (stallPayment) {
          return stallPayment.tax
      }
      return 0
    },
    getRefundAmount (stallId, stallsRefund) {
      const numericStallId = Number(stallId)
      const stallRefund = stallsRefund.find(item => item.stall_cat_id === numericStallId)
      if (stallRefund) {
          return stallRefund.refund_amount
      }
      return 0
    },
    getTotalProcessingFee () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallCategories = data.stall_categories || []
            const totalForThisData = stallCategories.reduce((total, category) => {
                const fee = parseFloat(category.processing_fee) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalSecurityMoney () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallCategories = data.stall_categories || []
            const totalForThisData = stallCategories.reduce((total, category) => {
                const fee = parseFloat(category.quoted_price * 0.25) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalStallFee () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.payment_amount) - (Number(stallPayment.vat) + Number(stallPayment.tax)) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalVat () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.vat) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalTax () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.tax) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalReturn () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallReturns = data.app_refund || []
            const totalForThisData = stallReturns.reduce((total, stallReturn) => {
                const fee = Number(stallReturn.refund_amount) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalIncludingVatTax () {
          const total = (
              (this.getTotalProcessingFee() || 0) +
              (this.getTotalStallFee() || 0) +
              (this.getTotalVat() || 0) +
              (this.getTotalTax() || 0) +
              (this.getTotalSecurityMoney() || 0) - (this.getTotalReturn() || 0)
          )
          return total
    },
    getTotalExcludingVatTax () {
      const total = (
          (this.getTotalProcessingFee() || 0) +
          (this.getTotalStallFee() || 0) +
          (this.getTotalSecurityMoney() || 0) - (this.getTotalReturn() || 0)
      )
      return total
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, retrunSecurityMoneyReport, this.search)
      if (result.success) {
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getSecurityMoney (data) {
        const Obj = this.$store.state.TradeFairService.ditfCommonObj.securityMoneyList.find(item => item.year === this.search.year)
        const percentage = Obj !== undefined ? Obj.percentage : 0
        return (percentage * data) / 100
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getFloorPrice (catId) {
      const cat = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => item.value === catId)
      if (cat !== undefined) {
        this.priceType = cat.price_type
        this.processingFee = cat.processing_fee
        this.stallSize = this.$i18n.locale === 'bn' ? cat.size_bn : cat.size_en
        return cat.floor_price
      } else {
        return 0
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStalls)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign(
        {
        request_type: 'pdf',
        local: this.$i18n.locale,
        org_id: 2,
        accounts: 1,
        year: this.search.year,
        stall_category: this.search.stall_category,
        fair_id: this.search.fair_id,
        circular_type: this.search.circular_type,
        total_processing_fee: this.getTotalProcessingFee(),
        total_security_fee: this.getTotalSecurityMoney(),
        total_stall_fee: this.getTotalStallFee(),
        total_vat: this.getTotalVat(),
        total_tax: this.getTotalTax(),
        total_refund: this.getTotalReturn(),
        grand_t_including_v_t: this.getTotalIncludingVatTax(),
        grand_t_excluding_v_t: this.getTotalExcludingVatTax()
        })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, retrunSecurityMoneyReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
